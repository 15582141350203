import React from "react"

function ErrorPage() {
    return (
        <>
        <div className="listing-page">
            <div className="container">
                <div className="view-listing-container">
                    <h1>Page not found</h1>
                </div>
            </div>
        </div>                    
        </>           
    )
}
export default ErrorPage;
