import React from "react";
import salesPropertiesQR from "../assets/img/sales-propeties.png";
import rentalPropertiesQR from "../assets/img/rental-properties.png";
// import snugQR from "../assets/img/snug-qr.png";
let qrcodeURL = "https://api.qrserver.com/v1/create-qr-code/?data=https://snug.com/apply/melcorprealestate&color=black&bgcolor=247-244-234";

export default function MyListing() {
    return (
        <div className="listing-page">
            <div className="container">
                <div className="view-listing-container">
                    <div className="view-listing-tile">
                        <div className="background-wrapper">
                            <h3>VIEW OUR PROPERTIES <br/> FOR <span>SALE</span></h3>
                            <p className="subtitle">Scan to explore all properties we have on the market for sale.</p>                            
                            <img src={salesPropertiesQR} 
                                className="qr-code img-fluid img-responsive" 
                                align="absmiddle" 
                                vspace="5" 
                                hspace="5" />
                        </div>
                    </div>
                    <div className="view-listing-tile">
                        <div className="background-wrapper">
                            <h3>VIEW OUR PROPERTIES <br/> FOR <span>RENT</span></h3>
                            <p className="subtitle">Scan to explore all properties we have on the market for rent.</p>                            
                            <img src={rentalPropertiesQR}
                                className="qr-code img-fluid img-responsive" 
                                align="absmiddle" 
                                vspace="5" 
                                hspace="5" />
                        </div>
                    </div>
                    <div className="view-listing-tile">
                        <div className="background-wrapper">
                            <h3>REGISTER YOUR INTEREST</h3>
                            <p className="subtitle">Scan now to register your interest and personalize your preferences for upcoming rental properties</p>                            
                            <img src={qrcodeURL}
                                className="qr-code img-fluid img-responsive" 
                                align="absmiddle" 
                                vspace="5" 
                                hspace="5" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}