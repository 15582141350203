import React from "react";
import logo from "../assets/img/melcorp-real-estate-color.svg";
export default function MyHeader() {
    return (
        <div className="header">
            <div className="container">
                <a href="/"><img src={logo} alt="logo" /></a>
            </div>
        </div>
    )
}