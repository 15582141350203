import { Outlet } from "react-router-dom";
import MyFooter from "../components/MyFooter";
import Header2 from "../components/Header2";
const Layout = () => {
    return (
        <>
            <Header2 />
            <div className="page-content">
                <Outlet />
            </div>
            <MyFooter />
        </>
    )
};

export default Layout;