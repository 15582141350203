import { React, useState } from "react";
import phoneIcon from "../assets/img/ICON_Phone.png";
import emailIcon from "../assets/img/ICON_Email.png";
function TeamMember(props) {
    const memberCustomFields = props.profile["toolset-meta"]["melcorp-teams"];
    const firstName = memberCustomFields["first-name"]["raw"];
    const lastName = memberCustomFields["last-name"]["raw"];
    const jobTitle = memberCustomFields["title"]["raw"];
    const photo = memberCustomFields["listing-image"]["raw"];
    const email = memberCustomFields["email-address"]["raw"];
    const agentType = memberCustomFields["department"]["raw"];
    let phone = memberCustomFields["mobile"]["raw"];
    const slug = props.profile.slug;
    const memberID = props.profile.id;
    let linkURL = window.location.href+"member-detail/"+slug;
    let qrcodeURL = "https://api.qrserver.com/v1/create-qr-code/?data="+window.location.href+"member-detail/"+slug+"&color=black&bgcolor=247-244-234";
    const backgroundStyle = {
        backgroundImage: 'url('+photo+')',
    }
    const goToMember = () => {
        window.location = linkURL;
    }
    if(agentType != "Sales") {
        phone = memberCustomFields["office-number"]["raw"];
    }
    return(
        <div className="aMember" key={memberID}>
            <div className="aMember__photo" style={backgroundStyle}></div>
            <div className="aMember__details-wrapper">
                <div className="aMember__left-column">
                    <div>
                        <h2 className="aMember__name">{firstName} {lastName}</h2>
                        <p className="aMember__title">{jobTitle}</p>
                    </div>
                    <div className="aMember__ctas-wrapper">
                        <div className="aMember__phone"><img src={phoneIcon} />{phone}</div>  
                        <div className="aMember__email"><img src={emailIcon} />{email}</div>
                    </div>
                </div>
                <div className="qrcode-link"><img alt="profile qr code" src={qrcodeURL} /></div>
            </div>
        </div>
    );
}
export default TeamMember;