import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";
import Map from "../components/Map";

function MemberDetail() {
    let params = useParams();
    let slug = params.slug;
    const [mem, setMem] = useState([]);
    const [Loaded, setLoaded] = useState(false);
    const getMember = () => {
        fetch('https://melcorprealestate.com.au/wp-json/wp/v2/melcorp-team?slug=' + slug)
        .then(res => res.json())
        .then((res) => {
            setMem(res[0]); // get the first one
            setLoaded(true);
        });
    }
    useEffect(() => {
        getMember();
    }, []);
    if(!Loaded) {
        return (
            <div className='container loading-container'>
                <div className='loader'>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--dot'></div>
                <div className='loader--text'></div>
                </div>
            </div>
        )
    }
    
    const copy = (e) => {
        e.preventDefault();
        const el = document.createElement('input');
        el.value = window.location.href;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        // Show copied message
        let existingCopied = document.getElementById('copiedID');
        if(existingCopied) {
            existingCopied.remove();
        }
        const copied = document.createElement('p');
        copied.className = 'copied';
        copied.id = 'copiedID';
        copied.innerHTML = 'URL copied to clipboard!';
        document.getElementById('share-wrapper').appendChild(copied);
    }
    const memberFields = mem['toolset-meta']['melcorp-teams'];
    const photo = memberFields["listing-image"]["raw"];
    const office = memberFields["melcorp-office"]["raw"];
    const backgroundStyle = {
        backgroundImage: 'url('+photo+')'
    }
    const email = memberFields['email-address']['raw'];
    let phone = memberFields["mobile"]["raw"];
    const agentType = memberFields["department"]["raw"];
    if(agentType != "Sales") {
        phone = memberFields["office-number"]["raw"];
    }
    return (
        <>
            <div className="member-detail-page">
                <div className="container">
                    <NavLink className="back-button" to="/"><span>&lt;</span> <p>Back</p></NavLink>
                    <div className="team-member-detail-wrapper">               
                        <div className="photo" style={backgroundStyle} ></div>
                        <div className="right-column">
                            <div className="info-wrap">
                                <h1 className="name">{memberFields['first-name']['raw']} {memberFields['last-name']['raw']}</h1>
                                <p className="title">{memberFields['title']['raw']}</p>
                                <p>{phone}</p>
                                <p>{email}</p>  
                            </div>
                            <div className="all-buttons-wrap">
                                <div className="orange-buttons">
                                    <a href={"tel:"+phone}>Call</a>
                                    <a href={"mailto:"+memberFields['email-address']['raw']}>Email</a>
                                </div>
                                <div className="clear-buttons" id="share-wrapper">
                                    <a className="clear-button" href={'https://melcorprealestate.com.au/wp-json/businesscards/v1/vcard/'+mem["id"]} >Add to contacts</a>
                                    <a className="clear-button" href="" onClick={copy}>Share contact</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Map office={office} />
            </div>
        </>
    )
}
export default MemberDetail;