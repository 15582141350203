import React from "react";
import { GoogleMap, useJsApiLoader , Marker, InfoWindow } from "@react-google-maps/api";
import mapPin from "../assets/img/icon-map-pointer.svg";
const containerStyle = {
    width: '100%',
    height: '100%'
};
const center = {
    lat: -37.806763236470594,
    lng: 144.96214206946155
};

const mapOptions = { styles: [
    {
        "featureType": "landscape",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 65
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 51
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 30
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 40
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": -25
            },
            {
                "saturation": -100
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#ffff00"
            },
            {
                "lightness": -25
            },
            {
                "saturation": -97
            }
        ]
    }
]};

function Map(props) {
    const office = (props.office);
    let officeAddress = "";
    let officeMapLink = "";
    switch(office) {
        case "Melcorp Head Office":
            officeAddress = "477 Swanston Street, Melbourne VIC 3000";
            officeMapLink = "https://www.google.com/maps/place/477%20Swanston%20Street%20Melbourne%20VIC%203000";
            break;
        case "Melcorp East":
            officeAddress = "308 Russell Street, Melbourne VIC 3000";
            officeMapLink = "https://www.google.com/maps/place/308%20Russell%20Street%20%20Melbourne%20VIC%203000";
            break;
        case "Melcorp Southbank":
            officeAddress = "11 Power Street, Southbank VIC 3006";
            officeMapLink = "https://www.google.com/maps/place/11%20Power%20Street%20Southbank%20VIC%203006";
            break;
        case "Melcorp Clayton":
            officeAddress = "Shop T02A 2107-2125 Dandenong Rd, Clayton VIC 3168";
            officeMapLink = "https://www.google.com/maps/place/2107-2125%20Dandenong%20Rd%20%20Clayton%20VIC%203168";
            break;            
    }
    const [showInfo, setShowinfo] = React.useState(false);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAU0eE5HQ1vJLvJUC8JsyEwu1jGLEs4smY',
        lib: ["places"]
    });
    const [map, setMap] = React.useState(null);
    const [mapinfowindow, setMapinfowindow] = React.useState(null);

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, []);
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    const showBubble = () => {
        setShowinfo(true);
    }
    const saveWindow = infoWindow => {
        setMapinfowindow(infoWindow);
    }
    if(!isLoaded) return <p>loading map...</p>
    
    return(        
        <>
        <div className="map-container">
            <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
            // onLoad={onLoad}
            onUnmount={onUnmount}
            options={mapOptions}
            >   
            
            <Marker icon={mapPin} position={center} onClick={() => {setShowinfo(true)}} />
            {showInfo === true && (
            <InfoWindow position={center} onLoad={saveWindow} onCloseClick={() => {setShowinfo(false)}}>
                <div className="map-info">
                    <p>{office}</p>
                    <p>{officeAddress}</p>
                    <a target="blank" href={officeMapLink}>Get directions</a>
                    </div>
            </InfoWindow>
            )}       
            </GoogleMap>
            </div>
        </>
    );
}
export default Map;