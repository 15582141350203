import React from "react";
import facebookicon from "../assets/img/facebook-icon.png";
import instagicon from "../assets/img/instag-icon.png";
import youtubeicon from "../assets/img/youtube-icon.png";
import linkedinicon from "../assets/img/linkedin-icon.png";

function MyFooter() {
    let date = new Date();
    return (
        <>
            <footer className="d-flex flex-column justify-content-center align-items-center">
                <p className="d-block">Follow us on</p>
                <ul>
                    <li>
                        <a target="_blank" href="https://www.facebook.com/melcorprealestate/"><img src={facebookicon} alt="facebook link" /></a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.instagram.com/melcorp_realestate/"><img src={instagicon} alt="instag link" /></a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.youtube.com/channel/UC5KElL-38n1LjxPQGh9op-g"><img src={youtubeicon} alt="youtube link" /></a>
                    </li>
                    <li>
                        <a target="_blank" href="https://au.linkedin.com/company/melcorp-realestate"><img src={linkedinicon} alt="linkedin link" /></a>
                    </li>
                </ul>
            </footer>
            {/* <div className="footer">   
                <p>Copyright {date.getFullYear()} Melcorp Real Estate</p>
                <div className="footer-links">
                    <ul>
                        <li><a href="https://giulianogroup.com.au/" target="_blank">Visit</a></li>
                        <li><a href="tel:+61396629996" target="_blank">Call</a></li>
                        <li><a href="https://www.instagram.com/giulianogroup/" target="_blank">Instagram</a></li>
                        <li><a href="https://www.linkedin.com/company/giuliano-group-australia/?originalSubdomain=au" target="_blank">LinkedIn</a></li>
                    </ul>
                </div>
            </div> */}
        </>
    )
}

export default MyFooter;