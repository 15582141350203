import React, { useState, useEffect, useRef } from "react";
import MemberList from "../components/MemberList";

function Homepage() {
    const [profiles, setProfiles] = useState([]);
    // const [DataisLoaded, setLoaded] = useState(false);
    // const [office, setOffice] = useState(false);
    const fullList = useRef([]);
    const [departmentFilter, setDepartmentFilter] = useState(false);
    const [officeFilter, setOfficeFilter] = useState("All");
    
    

    const sortJSON = (data) => {
        return data.sort(function(a, b) {
            if(parseInt(a["toolset-meta"]["melcorp-teams"]["order"]["raw"]) < parseInt(b["toolset-meta"]["melcorp-teams"]["order"]["raw"])) {
                return 1;
            }
            if(parseInt(a["toolset-meta"]["melcorp-teams"]["order"]["raw"]) >= parseInt(b["toolset-meta"]["melcorp-teams"]["order"]["raw"])) {
                return -1;
            }
            return 0;
        });
    };

    useEffect(() => {        
        fetch('https://melcorprealestate.com.au/wp-json/wp/v2/melcorp-team?per_page=40')
            .then(res => res.json())
            .catch(err => console.log(err))
            .then((json) => {
                json = sortJSON(json);
                let activeMember = json.filter((data) => { return data.status.includes('publish')});
                // Office
                const queryParameters = new URLSearchParams(window.location.search);
                const officeparam = queryParameters.get("office");
                if(officeparam) {
                    const officeActiveMember = activeMember.filter((data) => { return data['toolset-meta']['melcorp-teams']['melcorp-office']['raw'].includes(officeparam) });
                    if(officeActiveMember.length) {
                        activeMember = officeActiveMember;
                    }
                }
                setProfiles(activeMember);
                // setLoaded(true);
                fullList.current = activeMember;
                
            });
    }, []);
    let filterTimeOut; //debouncing
    const handleSearch = (query) => {
        clearTimeout(filterTimeOut); // debouncing
        if (query !== '') {
            filterTimeOut = setTimeout(() => { // debouncing
                let filteredItems = fullList.current.filter((data) => {
                    return data["toolset-meta"]["melcorp-teams"]["first-name"]["raw"].toLowerCase().includes(query.toLowerCase());
                });
                if(filteredItems.length) {
                    setProfiles(filteredItems);
                }
            }, 500);
        } else {
            setProfiles(fullList.current);
        }
    }
    const filterAgent = (query) => {
        let filteredItems = fullList.current.filter((data) => {
            return data["toolset-meta"]["melcorp-teams"]["department"]["raw"].toLowerCase().includes(query.toLowerCase());
        });
        setDepartmentFilter(query);
        setOfficeFilter(false);
        setProfiles(filteredItems);
    }
    const filterOffice = (e) => {
        const office = e.target.innerText;
        setOfficeFilter(office);
        setDepartmentFilter(false);
        if(office == 'All') {
            setProfiles(fullList.current);
        } else {
            let filteredItems = fullList.current.filter((data) => {
                return data['toolset-meta']['melcorp-teams']['melcorp-office']['raw'].includes(office);
            });
            
            setProfiles(filteredItems);
        }        
    }
    const clearField = () => {
        document.getElementById("search-input").value = "";
        handleSearch("");
        setDepartmentFilter(false);
    }
    
    if(!profiles.length) 
        return <div className='container loading-container'>
            <div className='loader'>
            <p>Loading agents</p>
            </div>
        </div>;
    
    return (
        <div className="ebusinesscard-page">
            <div className="container">
                <div className="top-bar">
                    <div className="search-bar">
                        <input type="text" placeholder="Search agent..." id="search-input" onChange={e => handleSearch(e.target.value)} />
                        <button onClick={clearField}>Reset</button>
                    </div>
                    <div className="filter-buttons">
                        <button className={departmentFilter === "Sales" ? "active" : ""} onClick={e => filterAgent('Sales')}>Sales</button>
                        <button className={departmentFilter === "Rental" ? "active" : ""} onClick={e => filterAgent('Rental')}>Property Management</button>
                    </div>
                </div>
                <div className="office-filter-buttons">
                    <button className={officeFilter === "Melcorp Head Office" ? "active" : ""} onClick={filterOffice}>Melcorp Head Office</button>
                    <button className={officeFilter === "Melcorp Southbank" ? "active" : ""} onClick={filterOffice}>Melcorp Southbank</button>
                    <button className={officeFilter === "Melcorp East" ? "active" : ""} onClick={filterOffice}>Melcorp East</button>
                    <button className={officeFilter === "Melcorp Clayton" ? "active" : ""} onClick={filterOffice}>Melcorp Clayton</button>
                    <button className={officeFilter === "All" ? "active" : ""} onClick={filterOffice}>All</button>
                </div>
                <MemberList profiles={profiles} />
            </div>            
        </div>
    )
}
export default Homepage;