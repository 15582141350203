import React from "react";
import saleAppraisalQR from "../assets/img/sale-appraisal.png";
import rentalAppraisalQR from "../assets/img/rental-appraisal.png";

export default function MyForm() {
    return (
        <div className="listing-page form-page">
            <div className="container view-listing-container form-container">
                {/* <div className="form-tile">
                    <div className="form-info">
                        <h3>Buyer registration form</h3>
                        <p>Scan to tell us tell us what you’re looking for. Fill in your preferred property features, price and location and let us get to work recommending you the perfect home.</p>
                    </div>
                    <div className="form-qr-code">
                        <img src="https://chart.googleapis.com/chart?cht=qr&chl=https://rebrand.ly/9sulrg7&chs=200x200&chld=L|0" 
                            className="" align="absmiddle" vspace="5" hspace="5" />
                    </div>
                </div> */}
                <div className="form-tile">
                    <div className="form-info">
                        <h3>Free Sales Appraisal</h3>
                    </div>
                    <div className="form-qr-code">
                        <img src={saleAppraisalQR} className="" />
                    </div>
                </div>
                <div className="form-tile">
                    <div className="form-info">
                        <h3>Free Rental Appraisal</h3>
                    </div>
                    <div className="form-qr-code">
                        <img src={rentalAppraisalQR} className="" />
                    </div>
                </div>
            </div>
        </div>
    )
}