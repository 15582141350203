import React from 'react';
import TeamMember from './TeamMember';
function MemberList(props) {
    return(
        <>
            {props.profiles.map((item, key) => (
                <TeamMember profile={item} key={key} />
            ))}
        </>
    )
}

export default MemberList;