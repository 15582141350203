import React from "react";
import logo from "../assets/img/melcorp-real-estate-color.svg";
import { NavLink } from "react-router-dom";
export default function MyHeader() {
    return (
        <div className="header">
            <div className="container">
                <a href="/"><img src={logo} alt="logo" /></a>            
                <div className="main-menu">
                    <ul>
                        <li className="menu-item"><NavLink to="/view-properties/">View Properties</NavLink></li>
                        <li className="menu-item"><NavLink to="/">Ebusiness cards</NavLink></li>
                        <li className="menu-item"><NavLink to="/forms/">Forms</NavLink></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}